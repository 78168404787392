<template>
  <div v-if="searchForm">
    <div>
      <v-text-field
        append-icon="mdi-magnify"
        v-model="searchForm.search"
        label="Tìm kiếm"
        clearable
        single-line
        outlined
        dense
        hide-details
      ></v-text-field>
    </div>
    <div class="mt-4">
      <div class="d-flex justify-between">
        <div class="mr-2" title="Số tờ khai nhập">
          <v-text-field
            label="Số TKN"
            clearable
            single-line
            outlined
            dense
            hide-details
            v-model="searchForm.declare_in_no"
          ></v-text-field>
        </div>
        <div title="Số tờ khai xuất">
          <v-text-field
            label="Số TKX"
            clearable
            single-line
            outlined
            dense
            hide-details
            v-model="searchForm.declare_out_no"
          ></v-text-field>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <div class="d-flex justify-between">
        <div class="mr-2" title="Ngày tờ khai nhập">
          <v-menu
            v-model="showPickDateNhap"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchForm.declare_in_date"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                label="Ngày TKN"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchForm.declare_in_date"
              range
            ></v-date-picker>
          </v-menu>
        </div>
        <div title="Ngày tờ khai xuất">
          <v-menu
            v-model="showPickDateXuat"
            :close-on-content-click="false"
            transition="scale-transition"
            nudge-left="150"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="searchForm.declare_out_date"
                append-icon="mdi-calendar"
                readonly
                outlined
                dense
                v-bind="attrs"
                v-on="on"
                label="Ngày TKX"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="searchForm.declare_out_date"
              range
            ></v-date-picker>
          </v-menu>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <v-select
        label="Khu vực hải quan"
        :items="customsAreaList"
        clearable
        single-line
        outlined
        dense
        hide-details
        item-value="id"
        :item-text="(item) => item.code + ' - ' + item.name"
        v-model="searchForm.declare_out_custom_id"
      ></v-select>
    </div>
    <div class="mt-4">
      <v-select
        label="Trạng thái thông quan xuất"
        :items="declareStatusList"
        clearable
        single-line
        outlined
        dense
        item-text="value"
        item-value="id"
        hide-details
        v-model="searchForm.declare_out_flow_status"
      ></v-select>
    </div>
    <div class="mt-4">
      <v-select
        label="Trạng thái thông quan nhập"
        :items="declareStatusList"
        clearable
        single-line
        outlined
        dense
        item-text="value"
        item-value="id"
        hide-details
        v-model="searchForm.declare_in_flow_status"
      ></v-select>
    </div>
    <div class="mt-4">
      <v-text-field
        label="Mã số thuế công ty xuất"
        clearable
        single-line
        outlined
        dense
        hide-details
        v-model="searchForm.company_out_tax"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  props: ["value", "customsAreaList"],
  computed: {
    searchForm: {
      get() {
        return this.value;
      },
      set(val) {
        let result = {
          ...val,
        };
        if (this.checkNotEmpty(result)) {
          result.contain_done = true;
        }
        this.$emit("input", result);
        this.$emit("onChange", result);
      },
    },
  },
  data: () => ({
    showPickDateXuat: false,
    showPickDateNhap: false,
    declareStatusList: [
      { id: false, value: "Chưa thông quan" },
      { id: true, value: "Thông quan" },
    ],
  }),
  methods: {
    checkNotEmpty(object) {
      let keys = Object.keys(object);
      if (keys.length == 0) return false;
      if (!keys.some((x) => object[x])) return false;
      return true;
    },
  },
};
</script>