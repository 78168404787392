<template>
  <PageListLayout
    icon="mdi-repeat"
    title="Tờ khai hải quan"
    subTitle="Danh sách tờ khai hải quan"
    useAdd
    titleAdd="Thêm tờ khai hải quan"
    @add="createItem"
    @reset-filter="resetFilter"
  >
    <template slot="side-right">
      <div class="d-flex">
        <div class="mr-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="var(--primary-color)"
                dark
                v-bind="attrs"
                v-on="on"
                @click="goImportGraft"
                :loading="loadingGraft"
              >
                <v-icon>mdi-arrow-collapse</v-icon>
              </v-btn>
            </template>
            <span>Ghép tờ khai</span>
          </v-tooltip>
          <input
            type="file"
            ref="importgraft"
            @change="handleImportGraft"
            hidden
          />
        </div>
        <div class="mr-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="var(--primary-color)"
                dark
                v-bind="attrs"
                v-on="on"
                @click="exportMultiple"
                :loading="loadingExportMultiple"
              >
                <v-icon>mdi-file</v-icon>
              </v-btn>
            </template>
            <span>Xuất giấy nộp tiền</span>
          </v-tooltip>
        </div>
        <div class="mr-2">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                color="red"
                dark
                v-bind="attrs"
                v-on="on"
                @click="deleteMultiple"
              >
                <v-icon> mdi-trash-can-outline </v-icon>
              </v-btn>
            </template>
            <span>Xoá</span>
          </v-tooltip>
        </div>
      </div>
    </template>
    <template slot="filter">
      <FilterComponent v-model="formSeach" :customsAreaList="customsAreaList" />
    </template>
    <CustomTable
      v-model="selected"
      :headers="headers"
      :items="tableData"
      :items-per-page="-1"
      hide-default-footer
      :loading="loading"
      loading-text="Đang tải dữ liệu ..."
      classPaging="pt-2"
      class="pb-8"
      show-select
      @toggle-select-all="handleSelecedAll"
    >
      <template v-slot:[`item`]="{ item }">
        <tr :ref="`id-${item.id}`">
          <td>
            <v-simple-checkbox
              :ripple="false"
              v-model="item.selected"
              @input="changeSelect"
            ></v-simple-checkbox>
          </td>
          <td>
            {{ item.so_hd }}
          </td>
          <td>
            {{ item.customs_area && item.customs_area.code }}
          </td>
          <td>
            {{ item.company_out_detail && item.company_out_detail.code }}
          </td>
          <td>{{ formatnumber(item.quantity) }} kg</td>
          <td>{{ formatnumber(item.price) }} đ</td>
          <td>
            <div
              :class="item.declare_out_no ? 'color-1' : 'disable'"
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                class="d-flex flex-column justify-center align-center"
                :class="[
                  isWarning(item.declare_out_date, item.declare_in_no)
                    ? 'color-4'
                    : '',
                ]"
              >
                {{ item.declare_out_no }}
                <a
                  class="style__atag"
                  v-if="item.declare_out_no && !item.declare_out_flow_status"
                  ref="kttq"
                  @click="fetchThongQuanType(item, 'tkx')"
                  >{{
                    loadingThongQuanTKX == item.id
                      ? `Đang kiểm tra thông quan...`
                      : `Kiểm tra thông quan`
                  }}</a
                >
              </div>
            </div>
          </td>
          <td>
            <div
              :style="
                `width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;`
              "
              :class="[
                isWarning(item.declare_out_date, item.declare_in_no)
                  ? 'color-4'
                  : '',
                item.declare_out_date ? 'color-1' : 'disable',
              ]"
            >
              {{ formatDate(item.declare_out_date) }}
            </div>
          </td>
          <td>
            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              :style="
                getStyleFlow(
                  item.declare_out_flow,
                  item.declare_out_date,
                  item.declare_out_flow_status
                )
              "
            >
              {{ item.declare_out_flow ? item.declare_out_flow : 0 }}
            </div>
          </td>
          <td>
            <div
              :class="item.declare_in_no ? 'color-2' : 'disable'"
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div class="d-flex flex-column justify-center align-center">
                <div>
                  {{ item.declare_in_no }}
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <span class="pointer" v-on="on" v-if="item.is_taxpayer">
                        <v-icon color="green" size="14"
                          >mdi-check-circle</v-icon
                        >
                      </span>
                    </template>
                    <span>Đã nộp thuế</span>
                  </v-tooltip>
                </div>
                <a
                  class="style__atag"
                  v-if="item.declare_in_no && !item.declare_in_flow_status"
                  ref="kttq"
                  @click="fetchThongQuanType(item, 'tkn')"
                  >{{
                    loadingThongQuanTKN == item.id
                      ? `Đang kiểm tra thông quan...`
                      : `Kiểm tra thông quan`
                  }}</a
                >
              </div>
            </div>
          </td>
          <td>
            <div
              :class="item.declare_in_date ? 'color-2' : 'disable'"
              :style="
                `width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;`
              "
            >
              {{ formatDate(item.declare_in_date) }}
            </div>
          </td>
          <td>
            <div
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
              :style="
                getStyleFlow(
                  item.declare_in_flow,
                  item.declare_in_date,
                  item.declare_in_flow_status
                )
              "
            >
              {{ item.declare_in_flow }}
            </div>
          </td>
          <!-- <td>
            <div
              :class="item.company_in_tax ? 'color-1' : 'disable'"
              style="
                width: 100%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <div
                class="d-flex flex-column justify-center align-center"
                :class="[
                  isWarning(item.declare_out_date, item.declare_in_no)
                    ? 'color-4'
                    : '',
                ]"
              >
                {{ item.company_out_detail.tax }}
                <a
                  class="style__atag"
                  v-if="
                    item.company_out_detail.tax && !item.declare_out_flow_status
                  "
                  ref="kttq"
                  @click="fetchThue(item)"
                  >{{
                    loadingCheckThue == item.id
                      ? `Đang kiểm tra thuế...`
                      : `Kiểm tra thuế`
                  }}</a
                >
              </div>
            </div>
          </td> -->
          <td>
            <v-menu>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :loading="loadingExportFromInfo == item.id"
                >
                  <v-icon color="textColor">mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="goEdit(item)">
                  <v-list-item-title>
                    <v-icon class="mr-2"> mdi-pencil </v-icon>
                    Cập nhật</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="exportByInfo(item)">
                  <v-list-item-title>
                    <v-icon class="mr-2"> mdi-file-export-outline </v-icon>
                    Xuất giấy nộp tiền</v-list-item-title
                  >
                </v-list-item>
                <v-list-item v-if="!item.is_taxpayer" @click="fetchThue(item)">
                  <v-list-item-title>
                    <v-icon class="mr-2"> mdi-card-text-outline </v-icon>
                    Kiểm tra thuế</v-list-item-title
                  >
                </v-list-item>
                <v-list-item @click="deleteItem(item)">
                  <v-list-item-title>
                    <v-icon class="mr-2"> mdi-delete </v-icon>
                    Xóa</v-list-item-title
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </td>
        </tr>
      </template>
    </CustomTable>
    <GraftImport ref="import_graft" :data="dataTableGraft" />
    <tokhai-dialog ref="toKhaiDialog" />
  </PageListLayout>
</template>
<script>
import API from "@/api/tokhaihaiquan";
import { getAllCustomsArea } from "@/api/khuvuchaiquan";
import { debounce } from "lodash";
import { FlowList, BgColorAlert } from "@/constants/tokhaihaiquan";
import FilterComponent from "./filter";
import { saveAs } from "file-saver";
import { numberFormat } from "@/utils/moneyFormat";
import { formatDate } from "@/utils/date";
import GraftImport from "./graft-table";
import sleep from "@/utils/sleep";
export default {
  components: {
    FilterComponent,
    GraftImport,
    tokhaiDialog: () => import("./components/tokhai-dialog.vue"),
  },
  data() {
    return {
      is_scroll: false,
      loadingGraft: false,
      FlowList,
      dataTableGraft: [],
      selected: [],
      page: 1,
      pageCount: 1,
      itemsPerPage: 10,
      tableData: [],
      customsAreaList: [],
      //loading
      btnLoading: false,
      loadingExportMultiple: false,
      loading: false,
      loadingThongQuanTKX: -1, // to khai xuat
      loadingThongQuanTKN: -1, // to khai nhap
      loadingExportFromInfo: -1,
      loadingCheckThue: -1,
      menu: {},
      search: "",
      formSeach: {
        declare_in_date: [],
        declare_out_date: [],
        company_out_tax: null,
        search: null,
        declare_in_flow_status: null,
        declare_out_flow_status: null,
        declare_out_custom_id: null,
        declare_out_no: null,
        declare_in_no: null,
      },
      showPickDateXuat: false,
      showPickDateNhap: false,
      headers: [
        {
          text: "Số HD",
          align: "center",
          sortable: false,
          value: "so_hd",
          class: "color-3",
        },
        {
          text: "KVHQ",
          align: "center",
          value: "customs_area.code",
          class: "color-3",
          sortable: false,
        },
        {
          text: "Công ty",
          align: "center",
          value: "company_out_detail.code",
          sortable: false,
          class: "color-3",
        },
        {
          text: "Khối lượng",
          value: "quantity",
          align: "center",
          sortable: false,
          class: "color-3",
        },
        {
          text: "Tổng tiền",
          value: "price",
          align: "center",
          sortable: false,
          class: "color-3",
        },
        {
          text: "Số tờ khai xuất",
          value: "declare_out_no",
          align: "center",
          sortable: false,
          class: "color-1",
        },
        {
          text: "Ngày TKX",
          value: "declare_out_date",
          align: "center",
          sortable: false,
          class: "color-1",
        },
        {
          text: "Luồng",
          value: "declare_out_flow",
          align: "center",
          sortable: false,
          class: "color-1",
        },
        {
          text: "Số tờ khai nhập",
          value: "declare_in_no",
          align: "center",
          sortable: false,
          class: "color-2",
        },
        {
          text: "Ngày TKN",
          value: "declare_in_date",
          align: "center",
          sortable: false,
          class: "color-2",
        },
        {
          text: "Luồng",
          value: "declare_in_flow",
          align: "center",
          sortable: false,
          class: "color-2",
        },
        // {
        //   text: "Kiểm tra thuế",
        //   value: "declare_in_flow",
        //   align: "center",
        //   sortable: false,
        //   class: "color-2",
        // },
        {
          text: "Hành động",
          value: "action",
          sortable: false,
          align: "center",
          width: "100",
        },
      ],
      targetID: null,
    };
  },
  computed: {},
  created() {
    this.getAllList();
    this.getAllCustomsAreaList();
    this.targetID = this.$router.currentRoute.query.targetID;
  },
  watch: {
    formSeach: {
      deep: true,
      handler: debounce(async function(val) {
        this.page = 1;
        this.getAllList();
      }, 300),
    },
  },
  methods: {
    handleSelecedAll(e) {
      this.tableData = e.items.map((x) => {
        x.selected = e.value;
        return x;
      });
    },
    changeSelect(event) {
      this.selected = this.tableData.filter((x) => x.selected);
    },
    checkNotEmpty(object) {
      let keys = Object.keys(object);
      if (keys.length == 0) return false;
      if (
        !keys.some((x) => {
          if (Array.isArray(object[x])) {
            return object[x].length;
          }
          return object[x];
        })
      )
        return false;
      return true;
    },
    goImportGraft() {
      this.$refs.importgraft.click();
    },
    async handleImportGraft(event) {
      let file = event.target.files[0];
      this.$refs.importgraft.value = null;
      let formData = new FormData();
      formData.append("file", file);
      this.loadingGraft = true;
      const res = await API.importGraft(formData);
      if (res && res.isDone) {
        this.getAllList();
        this.$refs.import_graft.open();
        this.dataTableGraft = res.data.data;
      }
      this.loadingGraft = false;
    },
    async fetchThue(item) {
      try {
        this.loadingCheckThue = item.id;
        const res = await API.checkThue({
          number: item.declare_in_no,
          tax: item.company_in_tax,
        });
        if (res && res.isDone) {
          let data = { id: item.id, ...res.data.data };
          await this.updateItem(data);
        }
      } finally {
        this.loadingCheckThue = -1;
      }
    },
    resetFilter() {
      this.formSeach = this.$options.data.call(this).formSeach;
    },
    async getAllCustomsAreaList() {
      this.customsAreaList = await getAllCustomsArea();
    },
    async getAllList() {
      try {
        this.loading = true;
        let params = { ...this.formSeach };
        if (this.checkNotEmpty(this.formSeach)) {
          params.contain_done = true;
        }
        let res = await API.list(params);
        this.tableData = res.data.map((x) => ({ ...x, selected: false }));
      } finally {
        this.loading = false;
        await sleep(1000);
        this.scrollToId();
      }
    },
    scrollToId() {
      if (this.$route.query.targetId) {
        this.$nextTick(() => {
          if (!this.is_scroll) {
            let field = `id-${this.$route.query.targetId}`;
            this.$refs[field].scrollIntoView({
              block: "center",
              behavior: "smooth",
            });
            this.$refs[field].classList.add("animation_change");
            this.is_scroll = true;
          }
        });
      }
    },
    changePage(val) {
      this.page = val;
      this.getAllList();
    },
    editItem(item) {
      this.$refs.form.showFormEdit(item);
    },
    async deleteItem(item) {
      this.$confirmBox.show({
        title: "Xóa tờ khai",
        width: 500,
        body: "Bạn có chắc chắn muốn xóa tờ khai?",
        action: async () => await API.destroy(item.id),
        onDone: () => this.getAllList(),
        disableMessage: true,
      });
    },
    getSourceName(list, id) {
      return list.find((item) => item.value === id)
        ? list.find((item) => item.value === id).name
        : null;
    },
    createItem() {
      this.$router.push({ path: "/tokhai/chinhsuatokhaihaiquan" });
    },
    goEdit(item) {
      this.$router.push({ path: `/tokhai/chinhsuatokhaihaiquan/${item.id}` });
    },

    formatnumber(number) {
      return number.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1.");
    },
    formatDate(date) {
      return formatDate(date);
    },
    calcDate(date) {
      let today = new Date();
      let flowInDate = new Date(date);
      today = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate(),
        0,
        0,
        0
      );
      flowInDate = new Date(
        flowInDate.getFullYear(),
        flowInDate.getMonth(),
        flowInDate.getDate(),
        0,
        0,
        0
      );
      const difference = Math.abs(today - flowInDate);
      let days;
      days = difference / (1000 * 3600 * 24);
      return days;
    },
    getBackgroundFlow(date, status, default_color) {
      if (!date) {
        return BgColorAlert["xam"];
      }
      let days = this.calcDate(date);
      // console.log(days);
      if (status) {
        return BgColorAlert["xanh"];
      }

      if (days < 5) {
        return "#fff";
      } else if (days >= 5 && days < 9) {
        return BgColorAlert["vang"];
      } else if (days >= 9 && days < 13) {
        return BgColorAlert["vang_dam"];
      } else if (days >= 13) {
        return BgColorAlert["do"];
      }
      return "";
    },
    getStyleFlow(id, date, status, default_color = "#fff") {
      let find = FlowList.find((x) => x.id == id);
      if (find && find.styles) {
        let result = { ...find.styles };
        result.background = this.getBackgroundFlow(date, status, default_color);
        result.fontWeight = "bold";
        return result;
      }
      return {};
    },
    isWarning(date, declare_in_no) {
      if (!declare_in_no) {
        let days = this.calcDate(date);
        if (days > 18) {
          return true;
        }
      }
      return false;
    },
    // export nhieu giay nop tien
    async exportMultiple() {
      if (this.selected.length > 0) {
        if (
          this.selected.some(
            (x) =>
              !x.declare_in_company_name ||
              !x.declare_in_no ||
              !x.declare_in_date ||
              !x.declare_in_custom_id
          )
        ) {
          this.warningExport();
          return;
        }
      }
      this.loadingExportMultiple = true;
      const res = await API.exportMultiplePaymentSlip({
        list_id: this.selected.map((x) => x.id),
      });
      if (res && res.isDone) {
        saveAs(new Blob([res.data]), "Ds Giấy nộp tiền.zip");
      }
      this.loadingExportMultiple = false;
    },
    // xoa nhiều
    deleteMultiple() {
      if (this.selected.length > 0) {
        this.$confirmBox.show({
          title: "Xóa tờ khai",
          width: 500,
          body: "Bạn có chắc chắn muốn xóa các tờ khai đã chọn?",
          action: async () =>
            await API.deleteMultiple({
              list_id: this.selected.map((x) => x.id),
            }),
          onDone: () => this.getAllList(),
          disableMessage: true,
        });
      }
    },
    //canh bao thieu thong tin cac to khai
    warningExport() {
      this.$toast.warning("Một trong các tờ khai chưa nhập đủ thông tin!", {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    // kt thong quan theo loai
    async fetchThongQuanType(item, type) {
      let result = {};
      let data = null;
      let key = {
        tkx: {
          status: "declare_out_flow_status",
          flow: "declare_out_flow",
          declare_no: "declare_out_no",
        },
        tkn: {
          status: "declare_in_flow_status",
          flow: "declare_in_flow",
          declare_no: "declare_in_no",
        },
      };
      //dk to khai xuat
      if (type == "tkx" && this.loadingThongQuanTKX == -1) {
        try {
          this.loadingThongQuanTKX = item.id;
          let find_declare_out_custom = this.customsAreaList.find(
            (x) => x.id == item.declare_out_custom_id
          );
          data = await this.fetchThongQuan(
            item.company_out_tax,
            item.declare_out_no,
            find_declare_out_custom?.code,
            item.declare_out_date
          );
        } finally {
          this.loadingThongQuanTKX = -1;
        }
        //dk to khai nhap
      } else if (type == "tkn" && this.loadingThongQuanTKN == -1) {
        try {
          this.loadingThongQuanTKN = item.id;
          data = await this.fetchThongQuan(
            item.company_in_tax,
            item.declare_in_no,
            item.declare_in_custom_id,
            item.declare_in_date
          );
        } finally {
          this.loadingThongQuanTKN = -1;
        }
      }
      if (data) {
        if (key[type]) {
          Object.keys(key[type]).forEach((k) => {
            if (data[k]) {
              result[key[type][k]] = data[k];
            }
          });
        }
      }
      if (Object.entries(result).length) {
        let data = { id: item.id, ...result };
        0;
        await this.updateItem(data);
      }
    },
    async updateItem(data) {
      delete data.customs_area;
      await API.update(data.id, data);
      this.success();
      this.getAllList();
    },
    //call api kiem tra thong quan
    async fetchThongQuan(
      company_tax,
      declare_no_param,
      declare_custom_id,
      declare_date
    ) {
      let result = {};
      const res = await API.checkThongQuan({
        company_tax: company_tax,
        declare_no: declare_no_param,
        declare_custom: declare_custom_id,
        declare_date: declare_date,
      });
      if (res && res.isDone) {
        return res.data.data;
      }
      return null;
    },
    infoMessange(message, timeout = 2500) {
      this.$toast.info(message, {
        position: "top-center",
        timeout,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    errorMessages(message, timeout = 2000) {
      this.$toast.error(message, {
        position: "top-center",
        timeout,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    //canh bao thieu info
    warningThongQuan() {
      this.$toast.warning("Vui lòng cập nhập đủ thông tin tờ khai!", {
        position: "top-center",
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    //xuat giay nop tien item
    async exportByInfo(item) {
      if (
        !item.declare_in_company_name ||
        !item.declare_in_no ||
        !item.declare_in_date
      ) {
        this.warningThongQuan();
        return;
      }
      let NK = item.declare_in_xnk ? item.declare_in_xnk : 0;
      let GTGT = item.declare_in_vat ? item.declare_in_vat : 0;
      let params = {
        company_name: item.declare_in_company_name,
        No: item.declare_in_no,
        DATE: item.declare_in_date,
        ma_hq: item.declare_in_custom_id,
        ma_loaihinh: "A12",
        NK: numberFormat(NK),
        GTGT: numberFormat(GTGT),
        Tong: numberFormat(NK + GTGT),
      };
      try {
        this.loadingExportFromInfo = item.id;
        const res = await API.exportPaymentSlip(params);
        saveAs(new Blob([res.data]), `GiayNopTien_${item.declare_in_no}.xls`);
      } finally {
        this.loadingExportFromInfo = -1;
      }
    },
    success() {
      this.$toast.success("Cập nhật thành công", {
        position: this.position,
        timeout: 1500,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
      });
    },
    uploadFile() {
      this.$refs.toKhaiDialog.open();
    },
  },
};
</script>
<style scoped>
/deep/ .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
  text-align: center;
}
/deep/.color-1 {
  background-color: #fffdf3 !important;
}
/deep/ .color-2 {
  background-color: #fffdf3 !important;
  /* background-color: #ffd69c63 !important; */
}
/deep/ .color-3 {
  background-color: #e1aef7bd !important;
}
/deep/ .v-data-table__checkbox.v-simple-checkbox {
  text-align: center;
}
/deep/.v-input--selection-controls__input {
  margin-right: 0 !important;
}
/deep/
  .v-input--selection-controls__input:hover
  .v-input--selection-controls__ripple:before {
  background: none !important;
}
/deep/
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  tr:first-child
  th:first-child {
  padding-right: 10px;
  padding-left: 10px;
}
</style>

<style lang="scss" scoped>
.style__atag {
  font-size: 0.725rem;
  &:hover {
    text-decoration: underline;
  }
}
.disable {
  background-color: #b7b7b7;
}
.animation_change {
  animation-name: changeColor;
  animation-duration: 4s;
}

@keyframes changeColor {
  50% {
    background-color: #b5e290;
  }
}
.color-4 {
  color: #f40000;
  font-weight: 600;
}
</style>
