<template>
  <v-dialog v-model="show" scrollable width="1200">
    <v-card>
      <v-card-title>Ghép tờ khai</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 500px">
        <div class="pa-2">
          <CustomTable
            :headers="headers"
            :items="tableData"
            hide-default-footer
            loading-text="Đang tải dữ liệu ..."
            :itemsPerPage="-1"
          >
            <template v-slot:[`item.status`]="{ item }">
              <div
                :class="`d-flex align-center color_${item.status}_status pl-4`"
                style="width: 100%; height: 100%"
              >
                {{ handleStatus(item) }}
              </div>
            </template>
          </CustomTable>
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <div class="d-flex">
        <v-card-actions class="ml-auto">
          <v-btn text @click="closeForm" class="mr-4">Đóng</v-btn>
          <v-btn
            class="mr-4"
            style="color: #fff"
            :loading="btnLoading"
            color="var(--primary-color)"
            @click="exportPaymentSlip"
            :disabled="disableExport"
          >
            Xuất giấy nộp tiền
          </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
import { StatusGraft, FlowList } from "@/constants/tokhaihaiquan";
import API from "@/api/tokhaihaiquan";
import { saveAs } from "file-saver";
import { numberFormat } from "@/utils/moneyFormat";
export default {
  props: ["data"],
  data: () => ({
    show: false,
    btnLoading: false,
    headers: [
      { text: "STT", align: "center", sortable: false, value: "stt" },
      {
        text: "Số tờ khai xuất",
        align: "start",
        sortable: false,
        value: "declare_out_no",
      },
      {
        text: "Số tờ khai nhập",
        align: "start",
        sortable: false,
        value: "declare_in_no",
      },
      {
        text: "Ngày TKN",
        align: "start",
        sortable: false,
        value: "declare_in_date",
      },
      {
        text: "Mã HQ",
        align: "start",
        sortable: false,
        value: "declare_in_custom_area",
      },
      {
        text: "Luồng",
        align: "start",
        sortable: false,
        value: "declare_in_flow",
      },
      {
        text: "Khối lượng",
        align: "start",
        sortable: false,
        value: "quantity",
      },
      {
        text: "Thuế NK",
        align: "start",
        sortable: false,
        value: "declare_in_xnk",
      },
      {
        text: "Thuế VAT",
        align: "start",
        sortable: false,
        value: "declare_in_vat",
      },
      { text: "Tổng tiền", align: "start", sortable: false, value: "price" },
      {
        text: "Trạng thái",
        align: "start",
        sortable: false,
        value: "status",
        width: "200px",
      },
    ],
  }),
  computed: {
    tableData() {
      return this.data.map((x, i) => {
        let find = FlowList.find((f) => f.id == x.declare_in_flow);
        x.declare_in_flow = find ? find.name : "";
        x.declare_in_xnk = x.declare_in_xnk
          ? numberFormat(x.declare_in_xnk)
          : 0;
        x.declare_in_vat = x.declare_in_vat
          ? numberFormat(x.declare_in_vat)
          : 0;
        x.quantity = x.quantity ? numberFormat(x.quantity) : 0;
        x.price = x.price ? numberFormat(x.price) : 0;
        return { stt: i + 1, ...x };
      });
    },
    disableExport() {
      return !this.data.some((x) => x.status == 1);
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    closeForm() {
      this.show = false;
    },
    handleStatus(item) {
      if (item.status) {
        let find = StatusGraft.find((x) => x.id == item.status);
        if (find) {
          return item.message ? item.message : find.text;
        }
      }
      return "";
    },
    async exportPaymentSlip() {
      let list_id = this.tableData
        .filter((x) => x.id && x.status == 1)
        .map((x) => x.id);
      this.btnLoading = true;
      const res = await API.exportMultiplePaymentSlip({
        list_id,
      });
      if(res && res.isDone){
        saveAs(new Blob([res.data]), "Ds Giấy nộp tiền.zip");
      }
      this.btnLoading = false;
    },
  },
};
</script>
<style scoped>
/deep/ tr td:last-child {
  padding-right: 0 !important;
}
.color_1_status {
  color: #fff;
  background-color: #05ad05;
}
.color_2_status {
  color: #fff;
  background-color: #fb2003;
}
.color_3_status {
  color: #000;
  background-color: #cdfb03;
}
</style>