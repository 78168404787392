var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('PageListLayout',{attrs:{"icon":"mdi-repeat","title":"Tờ khai hải quan","subTitle":"Danh sách tờ khai hải quan","useAdd":"","titleAdd":"Thêm tờ khai hải quan"},on:{"add":_vm.createItem,"reset-filter":_vm.resetFilter}},[_c('template',{slot:"side-right"},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mr-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"var(--primary-color)","dark":"","loading":_vm.loadingGraft},on:{"click":_vm.goImportGraft}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-arrow-collapse")])],1)]}}])},[_c('span',[_vm._v("Ghép tờ khai")])]),_c('input',{ref:"importgraft",attrs:{"type":"file","hidden":""},on:{"change":_vm.handleImportGraft}})],1),_c('div',{staticClass:"mr-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"var(--primary-color)","dark":"","loading":_vm.loadingExportMultiple},on:{"click":_vm.exportMultiple}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-file")])],1)]}}])},[_c('span',[_vm._v("Xuất giấy nộp tiền")])])],1),_c('div',{staticClass:"mr-2"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","color":"red","dark":""},on:{"click":_vm.deleteMultiple}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-trash-can-outline ")])],1)]}}])},[_c('span',[_vm._v("Xoá")])])],1)])]),_c('template',{slot:"filter"},[_c('FilterComponent',{attrs:{"customsAreaList":_vm.customsAreaList},model:{value:(_vm.formSeach),callback:function ($$v) {_vm.formSeach=$$v},expression:"formSeach"}})],1),_c('CustomTable',{staticClass:"pb-8",attrs:{"headers":_vm.headers,"items":_vm.tableData,"items-per-page":-1,"hide-default-footer":"","loading":_vm.loading,"loading-text":"Đang tải dữ liệu ...","classPaging":"pt-2","show-select":""},on:{"toggle-select-all":_vm.handleSelecedAll},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',{ref:("id-" + (item.id))},[_c('td',[_c('v-simple-checkbox',{attrs:{"ripple":false},on:{"input":_vm.changeSelect},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1),_c('td',[_vm._v(" "+_vm._s(item.so_hd)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.customs_area && item.customs_area.code)+" ")]),_c('td',[_vm._v(" "+_vm._s(item.company_out_detail && item.company_out_detail.code)+" ")]),_c('td',[_vm._v(_vm._s(_vm.formatnumber(item.quantity))+" kg")]),_c('td',[_vm._v(_vm._s(_vm.formatnumber(item.price))+" đ")]),_c('td',[_c('div',{class:item.declare_out_no ? 'color-1' : 'disable',staticStyle:{"width":"100%","height":"100%","display":"flex","justify-content":"center","align-items":"center"}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center",class:[
                _vm.isWarning(item.declare_out_date, item.declare_in_no)
                  ? 'color-4'
                  : '' ]},[_vm._v(" "+_vm._s(item.declare_out_no)+" "),(item.declare_out_no && !item.declare_out_flow_status)?_c('a',{ref:"kttq",staticClass:"style__atag",on:{"click":function($event){return _vm.fetchThongQuanType(item, 'tkx')}}},[_vm._v(_vm._s(_vm.loadingThongQuanTKX == item.id ? "Đang kiểm tra thông quan..." : "Kiểm tra thông quan"))]):_vm._e()])])]),_c('td',[_c('div',{class:[
              _vm.isWarning(item.declare_out_date, item.declare_in_no)
                ? 'color-4'
                : '',
              item.declare_out_date ? 'color-1' : 'disable' ],style:("width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;")},[_vm._v(" "+_vm._s(_vm.formatDate(item.declare_out_date))+" ")])]),_c('td',[_c('div',{staticStyle:{"width":"100%","height":"100%","display":"flex","justify-content":"center","align-items":"center"},style:(_vm.getStyleFlow(
                item.declare_out_flow,
                item.declare_out_date,
                item.declare_out_flow_status
              ))},[_vm._v(" "+_vm._s(item.declare_out_flow ? item.declare_out_flow : 0)+" ")])]),_c('td',[_c('div',{class:item.declare_in_no ? 'color-2' : 'disable',staticStyle:{"width":"100%","height":"100%","display":"flex","justify-content":"center","align-items":"center"}},[_c('div',{staticClass:"d-flex flex-column justify-center align-center"},[_c('div',[_vm._v(" "+_vm._s(item.declare_in_no)+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
return [(item.is_taxpayer)?_c('span',_vm._g({staticClass:"pointer"},on),[_c('v-icon',{attrs:{"color":"green","size":"14"}},[_vm._v("mdi-check-circle")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Đã nộp thuế")])])],1),(item.declare_in_no && !item.declare_in_flow_status)?_c('a',{ref:"kttq",staticClass:"style__atag",on:{"click":function($event){return _vm.fetchThongQuanType(item, 'tkn')}}},[_vm._v(_vm._s(_vm.loadingThongQuanTKN == item.id ? "Đang kiểm tra thông quan..." : "Kiểm tra thông quan"))]):_vm._e()])])]),_c('td',[_c('div',{class:item.declare_in_date ? 'color-2' : 'disable',style:("width: 100%; height: 100%; display: flex; justify-content: center; align-items: center;")},[_vm._v(" "+_vm._s(_vm.formatDate(item.declare_in_date))+" ")])]),_c('td',[_c('div',{staticStyle:{"width":"100%","height":"100%","display":"flex","justify-content":"center","align-items":"center"},style:(_vm.getStyleFlow(
                item.declare_in_flow,
                item.declare_in_date,
                item.declare_in_flow_status
              ))},[_vm._v(" "+_vm._s(item.declare_in_flow)+" ")])]),_c('td',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.loadingExportFromInfo == item.id}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"textColor"}},[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.goEdit(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-pencil ")]),_vm._v(" Cập nhật")],1)],1),_c('v-list-item',{on:{"click":function($event){return _vm.exportByInfo(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-file-export-outline ")]),_vm._v(" Xuất giấy nộp tiền")],1)],1),(!item.is_taxpayer)?_c('v-list-item',{on:{"click":function($event){return _vm.fetchThue(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-card-text-outline ")]),_vm._v(" Kiểm tra thuế")],1)],1):_vm._e(),_c('v-list-item',{on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-delete ")]),_vm._v(" Xóa")],1)],1)],1)],1)],1)])]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}}),_c('GraftImport',{ref:"import_graft",attrs:{"data":_vm.dataTableGraft}}),_c('tokhai-dialog',{ref:"toKhaiDialog"})],2)}
var staticRenderFns = []

export { render, staticRenderFns }